<script setup lang="ts">
import { ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { AutoCompleteItemSelectEvent } from 'primevue/autocomplete'
import { useAllUserDocuments } from '@/composables/document'
import { Document } from '@/services/document.service'
import MultiSelect from 'primevue/multiselect'
import { multiDocSelectedKey } from '@/utils/injectionKeys'
import AutoComplete, { AutoCompleteCompleteEvent } from 'primevue/autocomplete'
import { useDocument } from '@/composables/document'

const route = useRouter()
const documentId = ref(route?.currentRoute?.value?.params?.id ?? '')
const { data: documentData } = useDocument(documentId)

const props = defineProps<{
    isMutliDiscuss: boolean
}>()

const suggestions = ref<{ title: string; _id: string }[]>([])
const autocomplete = ref<InstanceType<typeof AutoComplete> | null>(null)
const selectedDocument = ref(null)
const selectedOptions = ref<Document[]>([])
const { data: userAllDocuments, isPending } = useAllUserDocuments()

const multiDocSelectedDocuments = inject(multiDocSelectedKey)
if (!multiDocSelectedDocuments) {
    throw new Error('multiDocSelectedDocuments was not provided')
}

const sortedDocuments = computed(() => {
    if (!userAllDocuments.value?.documents) return []

    return [...userAllDocuments.value.documents].sort((a, b) => {
        if (a._id === documentData.value?.Id) return -1
        if (b._id === documentData.value?.Id) return 1
        return 0
    })
})

watchEffect(() => {
    if (!props.isMutliDiscuss) {
        selectedOptions.value = []
    }
})

const { updateSelectedDocuments } = multiDocSelectedDocuments

const search = (e: AutoCompleteCompleteEvent) => {
    if (!userAllDocuments.value?.documents) {
        return []
    }

    if (!e.query.trim().length) {
        suggestions.value = [...userAllDocuments.value?.documents]
    } else {
        suggestions.value = [
            ...userAllDocuments.value?.documents.filter((item) =>
                item.title.toLowerCase().includes(e.query.toLowerCase())
            ),
        ]
    }
}

const itemSelectHandler = (e: AutoCompleteItemSelectEvent) => {
    if (e?.value?._id) {
        navigateTo(`/d/${e.value?._id}`, {
            open: { target: '_blank' },
        })
    }
}

watch(selectedOptions, (val) => {
    updateSelectedDocuments(val)
})

const openInNewTab = (documentId: string) => {
    navigateTo(`/d/${documentId}`, {
        open: { target: '_blank' },
    })
}

const openAllSelected = () => {
    selectedOptions.value.forEach((doc) => {
        navigateTo(`/d/${doc._id}`, {
            open: { target: '_blank' },
        })
    })
}

const clear = () => {
    // @ts-ignore
    autocomplete.value.$refs.focusInput.blur()
    // @ts-ignore
    autocomplete.value.$refs.focusInput.focus()
}
</script>
<template>
    <div v-if="!isMutliDiscuss" class="flex search-documents-control">
        <span class="p-input-icon-left flex flex-grow-1">
            <i class="pi pi-search"></i>
            <AutoComplete
                :dropdown="true"
                ref="autocomplete"
                v-model="selectedDocument"
                placeholder="Search my documents"
                @complete="search"
                :complete-on-focus="true"
                option-label="title"
                field="title"
                @item-select="itemSelectHandler"
                :suggestions="suggestions"
                :delay="250"
                @clear="clear"
                class="w-full"
                :disabled="isPending"
                :loading-icon="'pi pi-spin pi-spinner'"
                :loading="isPending"
                :multiple="false"
            />
        </span>
    </div>
    <div v-else class="flex search-documents-control">
        <span class="p-input-icon-left flex flex-grow-1">
            <i class="pi pi-search"></i>
            <MultiSelect
                :options="sortedDocuments ?? []"
                :maxSelectedLabels="1"
                :filter="true"
                v-model="selectedOptions"
                optionLabel="title"
                data-key="_id"
                placeholder="Select the documents"
                class="text-overflow-ellipsis flex-grow-1"
                :disabled="isPending"
                :loading="isPending"
                :filter-match-mode="'startsWith'"
            >
                <template #footer>
                    <div class="flex m-2 justify-content-between">
                        <div class="py-2 px-3">
                            <b>{{
                                selectedOptions ? selectedOptions.length : 0
                            }}</b>
                            item{{
                                (selectedOptions ? selectedOptions.length : 0) >
                                1
                                    ? 's'
                                    : ''
                            }}
                            selected
                        </div>
                        <div>
                            <Button
                                label="Open All"
                                size="small"
                                severity="success"
                                class="px-2 py-2 shadow-none"
                                v-tooltip.top="'Open All in new tab'"
                                @click="openAllSelected"
                                :disabled="selectedOptions.length < 2"
                            />
                        </div>
                    </div>
                </template>

                <template #option="slotProps">
                    <div
                        class="flex align-items-center justify-content-between w-full"
                        :key="slotProps.option._id"
                    >
                        <div class="flex-grow-1 text-overflow-ellipsis w-30rem">
                            <i
                                v-if="slotProps.option._id === documentData?.Id"
                                class="text-red-500 mr-1"
                            >
                                (Current Document) -
                            </i>
                            {{ slotProps.option.title }}
                        </div>
                        <i
                            aria-label="Edit"
                            class="pi pi-external-link ml-5"
                            v-tooltip.top="'Open in new tab'"
                            @click.stop="openInNewTab(slotProps.option._id)"
                        ></i>
                    </div>
                </template>
            </MultiSelect>
        </span>
    </div>
</template>
<style scoped lang="scss">
.search-documents-control {
    .pi {
        z-index: 1;
    }
}
::v-deep(.p-autocomplete-input) {
    padding-left: 30px;
    width: 250px !important;
    border-top-left-radius: 2rem !important;
    border-bottom-left-radius: 2rem !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;

    &:enabled:focus {
        box-shadow: none;
    }
}
::v-deep(.p-autocomplete-dropdown) {
    border-top-right-radius: 2rem !important;
    border-bottom-right-radius: 2rem !important;

    &:focus {
        box-shadow: none;
    }
}

::v-deep(.p-multiselect) {
    border-radius: 21px;
}

::v-deep(.p-multiselect-label-container) {
    padding-left: 20px;
    width: 250px !important;

    &:enabled:focus {
        box-shadow: none;
    }
}
::v-deep(.p-multiselect-trigger) {
    border-top-right-radius: 4rem !important;
    border-bottom-right-radius: 4rem !important;
    background: #0080ff;

    &:focus {
        box-shadow: none;
    }
}
::v-deep(.p-icon) {
    color: white;
}

::v-deep(.p-multiselect-item) {
    padding-right: 0.5rem !important;
}

.text-overflow-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
