import DocumentService from '@/services/document.service'
import { useQuery } from '@tanstack/vue-query'

export const useAllUserDocuments = () => {
    const { $documentService } = useNuxtApp()
    const documentService = $documentService as DocumentService

    return useQuery({
        queryKey: ['all-documents'],
        queryFn: documentService.getAllUserDocuments,
    })
}
